<!-- 检索学员出勤统计表 -->
<template>
    <div class="RetrievalTable">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="120px">
            <el-form-item label="日期查询" >
                  <el-date-picker :disabled="flag"
                    value-format="yyyy-MM-dd"
                       v-model="form.dateQuery"
                       type="daterange"
                       range-separator="至"
                       start-placeholder="开始日期"
                       end-placeholder="结束日期">
                     </el-date-picker>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择类型" @change="typeChange"
                :clearable="true">
                    <el-option
                  v-for="item in form.typeOpt"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="培训班级名称" v-if="this.form.type==1">
                <el-select v-model="form.course_name" filterable placeholder="请输入并选择培训班级名称"
                 :clearable="true" >
                    <el-option
                  v-for="item in form.course_nameOpt"
                  :key="item.id"
                  :label="item.class_name"
                  :value="item.id">
                </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="学生姓名" v-if="this.form.type==2">
                <el-select v-model="form.student" filterable remote placeholder="请输入并选择学生姓名" 
                :remote-method="students" :clearable="true">
                    <el-option
                  v-for="item in form.studentOpt"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id">
                </el-option>
                </el-select>
            </el-form-item>
            <div class="" style="margin-left: 22px;margin-top: 5px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="empty">
                    重置
                </el-button>
            </div>
        </el-form>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.8%;flex: 1;" height="650"  @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="student_name" sortable="custom"  label="学生姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="class_name" sortable="custom" label="课程名称" align="center" width="200">
            </el-table-column>
            <el-table-column prop="resources" sortable="custom" label="培训教师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="sign_type" sortable="custom" label="学籍类" align="center" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.sign_type==1">常规</span>
                    <span v-else-if="scope.row.sign_type==2">试学</span>
                    <span v-else-if="scope.row.sign_type==3">补课</span>
                </template>
            </el-table-column>
            <el-table-column prop="bz_lecture_number" sortable="custom" label="本班课次量" align="center" width="150">
            </el-table-column>
            <el-table-column prop="bm_lecture_number" sortable="custom" label="报名课次量" align="center" width="150">
            </el-table-column>
            <el-table-column prop="lessons" sortable="custom" label="已授课量" align="center" width="150">
            </el-table-column>
            <el-table-column prop="actual_size" sortable="custom" label="报名学员量" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course1" sortable="custom" label="课1" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course2" sortable="custom" label="课2" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course3" sortable="custom" label="课3" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course4" sortable="custom" label="课4" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course5" sortable="custom" label="课5" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course6" sortable="custom" label="课6" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course7" sortable="custom" label="课7" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course8" sortable="custom" label="课8" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course9" sortable="custom" label="课9" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course10" sortable="custom" label="课10" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course11" sortable="custom" label="课11" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course12" sortable="custom" label="课12" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course13" sortable="custom" label="课13" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course14" sortable="custom" label="课14" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course15" sortable="custom" label="课15" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course16" sortable="custom" label="课16" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course17" sortable="custom" label="课17" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course18" sortable="custom" label="课18" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course19" sortable="custom" label="课19" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course20" sortable="custom" label="课20" align="center" width="100">
            </el-table-column>
            <el-table-column prop="sign_status1" sortable="custom" label="签到次数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="sign_status2" sortable="custom" label="请假次数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="attendance" sortable="custom" label="出勤率(%)" align="center" width="150">
            </el-table-column>
            <el-table-column prop="student_card" sortable="custom" label="学生卡号" align="center" width="150">
            </el-table-column>
            <el-table-column  label="操作" align="center" fixed="right" width="120" >
                <template slot-scope="scope">
                    <el-button icon="el-icon-view" size="mini" style="background: #18BC9C;color:#FFFFFF;"
                    @click="empower(scope.$index, scope.row)">
                        详情
                    </el-button>
                    <!-- <el-button class="census" size="mini" style="color:#FFFFFF ;"
                    @click="census(scope.$index, scope.row)" >
                        统计表
                    </el-button> -->
                </template>
            </el-table-column>
           
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    dateQuery:"",//日期查询
                    type:1,//类型
                    typeOpt:[
                        {label: "培训班级", value: 1},
                        {label: "学生姓名", value: 2}
                    ],//类型选项
                    course_name:'',//培训班级名称
                    course_nameOpt:[],//培训班级名称选项
                    student:'',//学生姓名id
                    studentOpt:[]
                },
                tableData:[],//表格数据
                currentPage: 1,
                currentLimit:20,
                total: 0,
                flag:false
            }
        },
        created() {
            if(this.form.type==1){
                this.flag=true
            }
            // 培训班级名称数据
            this.$request({
                url:'/api/class_/list',
                method:'POST',
                data:{
                    page:1,
                    limit:500
                }
            }).then(res=>{
                if(res.code==1){
                    this.form.course_nameOpt=res.data.list
                }
            })
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url:'/api/classsign/statistics',
                    method:'POST',
                    data:{
                        datequery:this.form.dateQuery,
                        limit:this.currentLimit,
                        page:this.currentPage,
                        student_id:this.form.student,
                        class_id:this.form.course_name
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,'列表')
                        this.tableData=res.data.list
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            query(){//查询
                this.currentPage=1
                this.getList()
            },
            typeChange(e){
                // console.log(e)
                if(e==1){
                    this.flag=true
                }else{
                    this.flag=false
                }
            },
            //学生数据
            students(e) {
                // console.log(e);
                this.$request({
                    url: "/api/student/list",
                    method: "POST",
                    data: {
                        page: 1,
                        limit: 100,
                        key: e
                    }
                }).then(res => {
                    // console.log("下单人员数据", res.data.list)
                    this.form.studentOpt = res.data.list
                })
            },
            empty(){//重置
                this.form.dateQuery=''//查询日期
                if(this.form.type==1){
                    this.form.course_name=''
                }else if(this.form.type==2){
                    this.form.student=''//学生姓名id
                }
                this.getList()
            },
            empower(index,row){//详情
                this.$router.push({
                    path: "retrievalTableDetails",
                    query:{
                        id:row.id
                    }
                })
            },
            census(index,row){//统计表
                this.$router.push({
                    path: "censusTable",
                    query:{
                        id:row.id
                    }
                })
            },
            // 姓名排序
            fn2(a, b){
                return a.student_name.localeCompare(b.student_name)
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
        
    }
</script>

<style scoped="scoped">
    .RetrievalTable{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        flex-wrap: wrap; 
        /* justify-content: space-between;
        */
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    .census{
        padding-left: 30px;
        background: url(../../assets/tj.png) 10px 4px no-repeat #29726C;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>